import React, { Component } from 'react'
import BannerImage from '../components/elements/BannerImage'
import banner from '../images/slicing/service/banner.png'
import WorkStrategy from '../components/services/WorkStrategy'
import CaseStudyComponent from '../components/services/CaseStudyComponent'
import TestimonialComponent from '../components/services/TestimonialComponent'
import { testingStrategyServicesData, testingStrategy } from '../staticdata/homepageServicesData'
import ServicesNewComponent from '../components/services/ServicesNewComponent'
class TestingStrategyContainer extends Component {
    render() {
        return (
            <>
                <BannerImage
                    activeTab="Test Engineering"
                    bannerImage={banner}
                    text={`We help build a Testing Strategy which gives you confidence on Quality, Speed for on-time delivery and value addition in entire process`} />
                <WorkStrategy data={testingStrategy} />
                <ServicesNewComponent
                    heading="Offerings & solutions"
                    height="350px"
                    bgColor='#ffffff'
                    page="services"
                    data={testingStrategyServicesData} />
                <CaseStudyComponent />
                <TestimonialComponent />
            </>
        )
    }
}

export default TestingStrategyContainer