import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import TestingStrategyContainer from "../containers/TestingStrategyContainer"

const TestingStrategy = () => (
  <Layout
    pageInfo={{ pageName: "Test Engineering" }}
    styleClass="home-page"
    location={'/productengineering/'}>
    <SEO title="Test Engineering" />
    <TestingStrategyContainer />
  </Layout>
)

export default TestingStrategy
